<template>
    <div class="action-refund-status">
        <div class="profile-container" @click="clickProfile">
            <img class="photo flex-wrap" :src="user.photo_url" @error="$event.target.src = $blankProfile" />
            <div class="profile">
                <div class="name" v-html="`${$nameOrNick(user)}, ${$options.filters.asAge((user || {}).birthday)}`" />
            </div>

            <div class="profile-btn">
                <i class="material-icons">account_box</i>
            </div>
        </div>
        <div class="hr" />
        <div class="question" v-html="question" />
        <div class="button-container">
            <button
                v-for="status in refundStatus"
                :key="status.key"
                class="status-button"
                @click="onActive(status.key)"
                :class="{
                    active: status.active,
                    kakaoMode: $kakaoMode(),
                }"
            >
                <span class="text" v-html="status.text" />
            </button>
        </div>
        <div v-if="refunded" class="bottom" v-html="$translate('ACTION_REFUND_STATUS_MESSAGE')" />
    </div>
</template>

<script>
import datingService from '@/services/dating'
import refundService from '@/services/refund'

export default {
    name: 'ActionRefundStatus',
    props: ['message'],
    components: {},
    data: () => ({
        clickedReject: false,
        clickedContact: false,
        clickedMeet: false,
        shouldBlock: false,
    }),
    watch: {
        content() {},
    },
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        user() {
            return this.content.user || {}
        },
        profile() {
            return this.chat.user.profile
        },
        refundStatus() {
            // 환급된 경우
            if (this.refunded) {
                return [
                    {
                        key: 'reject',
                        text: this.$translate('REFUND_STATUS_REJECT'),
                        active: this.clickedReject || this.content.refund_reason_code === 'reject',
                    },
                    {
                        key: 'contact',
                        text: this.$translate('REFUND_STATUS_CONTACT'),
                        active: this.clickedContact || this.content.refund_reason_code === 'contact',
                    },
                    {
                        key: 'meet',
                        text: this.$translate('REFUND_STATUS_MEET'),
                        active: this.clickedMeet || this.content.refund_reason_code === 'meet',
                    },
                ]
            }

            return [
                {
                    key: 'contact',
                    text: this.$translate('REFUND_STATUS_CONTACT'),
                    active: this.clickedContact || this.content.refund_reason_code === 'contact',
                },
                {
                    key: 'meet',
                    text: this.$translate('REFUND_STATUS_MEET'),
                    active: this.clickedMeet || this.content.refund_reason_code === 'meet',
                },
                {
                    key: 'reject',
                    text: this.$translate('REFUND_STATUS_REJECT2'),
                    active: this.clickedReject || this.content.refund_reason_code === 'reject',
                },
            ]
        },
        question() {
            if (!this.content) return

            const str = this.$translate(
                this.content.refund_id
                    ? 'ACTION_REFUND_STATUS_QUESTION_REFUND'
                    : 'ACTION_REFUND_STATUS_QUESTION_EXPIRED',
            )

            return str.replace(/%s/, this.$nameOrNick(this.user))
        },
        refunded() {
            return !!this.content.refund_id
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        async init() {
            try {
                if (!this.content.is_checked && this.content.refund_id) {
                    await refundService.checked(this.content.refund_id, { message_id: this.message.id })
                }
            } catch (e) {}
        },

        onActive(key) {
            if (this.content.refund_reason_code || this.shouldBlock) {
                this.$toast.error('이미 제출되었어요')
                return
            }

            switch (key) {
                case 'contact':
                    this.clickedContact = true
                    break
                case 'meet':
                    this.clickedMeet = true
                    break
                case 'reject':
                    this.clickedReject = true
                    break
            }

            setTimeout(() => this.onSelect(key), 500)
        },
        clickProfile() {
            this.$stackRouter.push({
                name: 'UserDetailPage',
                props: {
                    from: 'ActionRefundStatus',
                    userId: this.user.id,
                    photos: [{ url: this.user.photo_url }],
                },
            })
        },
        async onSelect(key) {
            if (!this.content.dating_id) {
                this.$toast.error('평가할 수 있는 기간이 지났습니다')

                return
            }

            try {
                this.$loading(true)
                const datings = await datingService.datings(this.content.user.id)
                const dating = datings.find(d => d.id === this.content.dating_id)
                const dateDiff = this.$options.filters.dateDiff(dating.created_at)

                if (dateDiff > 20) {
                    this.$toast.error('피드백은 소개팅 성사 후 20일 동안만 가능해요')

                    return
                }

                if (key === 'contact') {
                    // refund_id가 없다는 것은 10일 후 자동으로 온 메시지
                    if (!this.content.refund_id) {
                        const paid = dating.paid
                        const body = paid
                            ? '아직 만나자고 제안하지 않았다면, 얼른 만남 약속을 잡는 걸 추천해요!'
                            : '아직 만나지 않았다면, 얼른 만남 약속을 잡는 걸 추천해요!'

                        this.$modal.basic({
                            body,
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                    }

                    await datingService.feedback(this.content.dating_id, { code: key, message_id: this.message.id })
                    this.shouldBlock = true // 임시로 버튼 동작 막기

                    return
                }

                await this.$store.dispatch('loadFeedbackOptions')
                this.$store.commit('setFeedbackPayload', {
                    code: key,
                    message_id: this.message.id,
                    from: 'auto_message',
                })
                this.$store.commit('setFeedbackDating', dating)

                if (key === 'meet') {
                    this.$stackRouter.push({
                        name: 'DatingFeedbackPage',
                        props: {
                            user: this.content.user,
                        },
                    })
                } else if (key === 'reject') {
                    this.$stackRouter.push({
                        name: 'CommunicationFeedbackPage',
                    })
                }
            } catch (e) {
            } finally {
                this.$loading(false)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.action-refund-status {
    .profile-container,
    .question,
    .button-container {
        padding: 0 12px;
    }

    .hr {
        width: 100%;
        height: 1px;
        background: $grey-02;
        margin-top: 8px;
        margin-bottom: 12px;
    }

    .profile-container {
        display: flex;

        .photo {
            width: 44px;
            height: 44px;
            border-radius: 40%;
            border: solid 1px $grey-02;
            margin-right: 12px;
        }

        .profile {
            width: 100%;
            align-self: center;

            .name {
                font-size: 14px;
                height: 20px;
                color: black;

                @include f-medium;
            }

            .sub {
                display: flex;
                font-size: 12px;
                width: 100%;

                .job,
                .location {
                    height: 18px;
                    color: $grey-07;
                }
                .bar {
                    color: $grey-04;
                    margin-left: 4px;
                    margin-right: 4px;
                }
            }
        }

        .profile-btn {
            width: 36px;
            min-width: 36px;
            height: 36px;
            border-radius: 8px;
            background-color: $grey-02;
            display: flex;
            object-fit: contain;
            justify-content: center;
            align-items: center;
        }
    }

    .question {
        height: 48px;
        font-size: 16px;
        color: black;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        margin-bottom: 16px;
        letter-spacing: -0.2px;
        line-height: normal;

        @include f-medium;
    }

    button {
        &[disabled],
        &.disabled {
            @include f-regular;
        }
    }

    .button-container {
        .status-button {
            margin-bottom: 8px;
            padding: 8px 0;
            width: 100%;
            height: auto;
            border-radius: 8px;
            border: 0;
            flex-direction: row;
            align-items: center;
            background-color: $grey-01;
            font-weight: 500;
            font-size: 14px;
            color: $grey-09;
            line-height: normal;

            &.active {
                border: solid 1px $purple-primary;
                background-color: $purple-primary;
                color: white;

                &.kakaoMode {
                    border: none;
                    background: $yellow-kakao;
                    color: black;
                }
            }
        }
    }

    .bottom {
        font-size: 12px;
        line-height: 1.33;
        text-align: center;
        color: $grey-08;
        margin-bottom: 12px;
    }
}
</style>
